@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  padding: 0;
  margin: 0;
  font-size: 10px;
  font-family: 'Aeonik';
  text-rendering: optimizeLegibility;
  @apply antialiased;
}

/*  -webkit-touch-callout: none; // iOS Safari */
/*  -webkit-user-select: none; // Safari */
/*  -khtml-user-select: none; // Konqueror HTML */
/*  -moz-user-select: none; // Old versions of Firefox */
/*  -ms-user-select: none; // Internet Explorer/Edge */
/*  user-select: none; // Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */

img {
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

@layer base {
  @font-face {
    font-family: 'Aeonik';
    src: url(/fonts/Aeonik/Aeonik-Regular.ttf) format('truetype');
    font-weight: normal;
  }
  @font-face {
    font-family: 'Aeonik Thin';
    src: url(/fonts/Aeonik/Aeonik-Thin.ttf) format('truetype');
    font-weight: 200;
  }

  @font-face {
    font-family: 'Aeonik';
    src: url(/fonts/Aeonik/Aeonik-Medium.ttf) format('truetype');
    font-weight: 500;
  }
}

@layer utilities {
  .copy-l {
    @apply text-mobile-l;
  }
  .copy-xl {
    @apply text-mobile-xl;
  }
  @screen lgt {
    .copy-l {
      @apply text-desktop-l;
    }
    .copy-xl {
      @apply text-desktop-xl;
    }
  }
  /* .copy-caption {
    letter-spacing: calc(-0.01 * 1.2rem);
    @apply text-caption;
  }
  .copy-s {
    letter-spacing: calc(-0.01 * 1.4rem);
    @apply text-small;
  }
  .copy-body {
    letter-spacing: calc(-0.01 * 1.6rem);
    @apply text-body;
  }
  .copy-mobile-m {
    letter-spacing: calc(-0.01 * 2rem);
    @apply text-mobile-m;
  }
  .copy-mobile-xm {
    letter-spacing: calc(-0.01 * 2.4rem);
    @apply text-mobile-xm;
  }
  .copy-mobile-l {
    letter-spacing: calc(-0.02 * 3.2rem);
    @apply text-mobile-l;
  }
  .copy-mobile-xl {
    letter-spacing: calc(-0.02 * 4rem);
    @apply text-mobile-xl;
  }
  .copy-mobile-xxl {
    letter-spacing: calc(-0.03 * 4.8rem);
    @apply text-mobile-xxl;
  }
  .copy-desktop-m {
    letter-spacing: calc(-0.01 * 2.4rem);
    @apply text-desktop-m;
  }
  .copy-desktop-xm {
    letter-spacing: calc(-0.01 * 3.2rem);
    @apply text-desktop-xm;
  }
  .copy-desktop-l {
    letter-spacing: calc(-0.01 * 4rem);
    @apply text-desktop-l;
  }
  .copy-desktop-xl {
    letter-spacing: calc(-0.03 * 4.8rem);
    @apply text-desktop-xl;
  }
  .copy-desktop-xxl {
    letter-spacing: calc(-0.03 * 6.4rem);
    @apply text-desktop-xxl;
  } */
  .noscrollbar {
    scrollbar-width: none;
    margin-bottom: 0;
    padding-bottom: 0;
  }
  .noscrollbar::-webkit-scrollbar {
    display: none;
  }
  .push-fullscreen-margin {
    margin-left: calc((-100vw + 1128px) / 2);
  }
  .push-fullscreen-padding {
    padding-left: calc((100vw - 1128px) / 2);
  }
  .transition-dark {
    @apply transition-colors duration-700 ease-linear;
  }
  .grabbable {
    cursor: move; /* fallback if grab cursor is unsupported */
    cursor: grab;
    cursor: -moz-grab;
    cursor: -webkit-grab;
  }

  /* (Optional) Apply a "closed-hand" cursor during drag operation. */
  .grabbable:active {
    cursor: grabbing;
    cursor: -moz-grabbing;
    cursor: -webkit-grabbing;
  }
}
